.editorSt {
  height: 10.625rem;
  overflow: auto;
  box-sizing: border-box;
  border-radius: .325rem;
  padding: 0 5px 0 5px;
  border: solid 1px #efefef;
}
.editorSt-v2 {
  height: 9rem;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Roboto','sans-serif';
  border: solid 1px #CBD5E0;
}
.editorSt-v2-mini {
  height: 6.5rem;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Roboto','sans-serif';
  border: solid 1px #CBD5E0;
}
.editorSt-v3 {
  height: 13rem;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px;
  font-family: 'Roboto','sans-serif';
  border: solid 1px #CBD5E0;
}
.editorSt-v4 {
  height: 17rem;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px;
  font-family: 'Roboto','sans-serif';
  border: solid 1px #CBD5E0;
}

.editorSt-v2-focus{
  border-color: #3182ce !important;
  box-shadow: 0 0 0 1px #3182ce !important;
  transition:0.2s;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  font-size: 12px;
  border: 1px solid #ccc;
}

.dropStyle label:first-child{
  visibility: hidden;
}

.dropStyle input{
  border-color:var(--c6);
}

.dropStyle label:nth-child(3){
  visibility: hidden;
}

.dropStyle label:first-child:before{
  visibility: visible;
  content:'Titulo do link'; 
}

.dropStyle label:nth-child(3):before{
  visibility: visible;
  content:'Link'; 
}

.dropStyle span:nth-child(2){
  visibility: hidden;
  word-break: break-all;
}

.dropStyle span:nth-child(2)::before{
  visibility: visible;
  content:'Abrir em uma nova aba'; 
}

.dropStyle button:first-child {
  visibility: hidden;
}

.dropStyle button:first-child {
  visibility: hidden;
}

.dropStyle button:first-child:after {
  content:'Adicionar'; 
  visibility: visible;
  display: block;
  background-color: var(--Green2);
  position: relative;
  top: -3rem;
}


.dropStyle button:last-child {
  visibility: hidden;
}

.dropStyle button:last-child:after {
  content:'Cancelar'; 
  visibility: visible;
  display: block;
  background-color: var(--c6);
  position: relative;
  top: -3rem;
}
